<template>
  <div class="date-card" :class="{
    'date-card--selected': selected,
    'date-card--unavailable': event.unavailable
  }">
    <span v-if="event.unavailable" class="text-blue">Indisponível</span>
    <span v-else>{{ time }}</span>
  </div>
</template>

<script>
export default {
  name: 'DateCard',
  props: ['event', 'selected'],
  computed: {
    time() {
      return this.event.timestamp.format('HH:mm');
    }
  }
}
</script>

<style lang="scss">
  .date-card {
    @apply relative z-10 flex items-center justify-center text-center text-black text-opacity-50 h-full;
    border: 1px solid rgba(0, 0, 0, 0.1)!important;
    border-radius: 4px;

    &:before {
      @apply absolute inset-0 bg-cover transition duration-300 opacity-0;
      content: '';
      z-index: -1;
      background-image: url(~@/assets/images/unavailable.png);
    }
  }

  .date-card--unavailable {
    @apply bg-salmon bg-opacity-25;
  }

  .date-card--selected {
    @apply transition duration-300;

    &:before {
      @apply opacity-100;
    }

    &.date-card--unavailable {
      @apply bg-salmon bg-opacity-100 text-white;

      &:before {
        @apply opacity-0;
      }
    }
  }
</style>
