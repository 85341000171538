<template>
  <button class="fixed bottom-14 right-14 flex items-center justify-center bg-white rounded-full w-20 h-20 shadow-md transition duration-300 focus:outline-none hover:bg-gray-50" @click="$emit('click')">
    <svg v-if="type === 'delete'" width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6667 9V22.3333H5V9H15.6667ZM13.6667 1H7L5.66667 2.33333H1V5H19.6667V2.33333H15L13.6667 1ZM18.3333 6.33333H2.33333V22.3333C2.33333 23.8 3.53333 25 5 25H15.6667C17.1333 25 18.3333 23.8 18.3333 22.3333V6.33333Z" fill="#00136F" stroke="white"/>
    </svg>

    <svg v-else width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.0046 24.0625L25.8296 23.7C25.0671 23.6125 24.3171 23.875 23.7796 24.4125L22.7296 25.4625C19.1921 23.6625 16.2921 20.775 14.4921 17.225L15.5546 16.1625C16.0921 15.625 16.3546 14.875 16.2671 14.1125L15.9046 10.9625C15.7546 9.7 14.6921 8.75 13.4171 8.75H11.2546C9.84209 8.75 8.66709 9.925 8.75459 11.3375C9.41709 22.0125 17.9546 30.5375 28.6171 31.2C30.0296 31.2875 31.2046 30.1125 31.2046 28.7V26.5375C31.2171 25.275 30.2671 24.2125 29.0046 24.0625Z" stroke="#00136F" stroke-width="1.5"/>
      <line x1="5.29289" y1="33.2929" x2="30.2929" y2="8.29289" stroke="#00136F" stroke-width="2"/>
      <line x1="6.93934" y1="34.9393" x2="31.9393" y2="9.93934" stroke="white" stroke-width="3"/>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'ActionButton',
  props: ['type']
}
</script>

<style lang="scss">

</style>
