<template>
  <div class="calendar-filter-modal fixed top-0 left-0 flex items-center justify-center w-full h-full opacity-0">
    <div class="calendar-filter-modal__card bg-white p-10 opacity-0 rounded-lg">
      <div class="calendar-filter-nav flex items-center justify-center flex-none">
        <button class="focus:outline-none" @click="prevYear">
          <arrow-left />
        </button>

        <h3 class="calendar-nav-label text-blue text-lg font-light px-4">{{ currentYear }}</h3>

        <button class="focus:outline-none" @click="nextYear">
          <arrow-right />
        </button>
      </div>

      <div class="calendar-filter-body mt-12">
        <div class="calendar-filter-buttons grid justify-center">
          <button
            v-for="(month, index) in months"
            :key="index"
            class="calendar-filter-button rounded-xl py-2 px-5 focus:outline-none transition duration-300 ease-in-out"
            :class="currentMonth === index ? 'bg-salmon text-white' : 'bg-cream text-blue'"
            @click="selectMonth(month, index)"
          >
            {{ month }}
          </button>
        </div>

        <div class="calendar-filter-actions flex items-center justify-between mt-16 w-full">
          <Button ghost @click="closeCalendarFilter" class="mr-8">Cancelar</Button>
          <Button @click="confirm">Selecionar</Button>
        </div>
      </div>
    </div>

    <span class="calendar-filter-modal-layer fixed top-0 left-0 w-full h-full bg-black opacity-0 cursor-pointer" @click="closeCalendarFilter" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Button from '@/components/Button';
import ArrowLeft from '@/components/ArrowLeft';
import ArrowRight from '@/components/ArrowRight';
import dayjs from 'dayjs';

export default {
  name: "CalendarFilterModal",
  props: ['date'],
  components: {
    ArrowRight,
    ArrowLeft,
    Button,
  },
  data() {
    return {
      currentYear: 2020,
      currentMonth: 0,
      currentDay: 4,
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
    }
  },
  watch: {
    date() {
      this.refreshDate();
    }
  },
  methods: {
    ...mapMutations(['closeCalendarFilter']),

    refreshDate() {
      const date = dayjs(this.date);

      this.currentMonth = date.format('M') - 1;
      this.currentYear = parseInt(date.format('YYYY'), 10);
    },

    nextYear() {
      this.currentYear += 1;
    },

    prevYear() {
      this.currentYear -= 1;
    },

    selectMonth(month, index) {
      this.currentMonth = index;
    },

    confirm() {
      const date = `${this.currentYear}-${this.currentMonth + 1}-${this.currentDay}`;
      this.$emit('change', date);
      this.closeCalendarFilter();
    }
  },
};
</script>

<style lang="scss">
.has-calendar-filter-modal {
  .calendar-filter-modal {
    opacity: 1;
    pointer-events: auto;
  }

  .calendar-filter-modal-layer {
    @apply opacity-30;
  }

  .calendar-filter-modal__card {
    opacity: 1;
    transform: none;
  }
}

.calendar-filter-modal {
  z-index: 1;
  bottom: 0;
  pointer-events: none;
  overflow-y: scroll;
  transition: opacity 0.3s, transform .3s;
}

.calendar-filter-modal-layer {
  z-index: -1;
}

.calendar-filter-modal__card {
  width: 610px;
  transform: scale(0.90);
  transition: transform .3s, opacity 0.3s;
}

.calendar-filter-buttons {
  grid-template-columns: repeat(3, 140px);
  grid-template-rows: 40px;
  grid-column-gap: 40px;
  grid-row-gap: 25px;
}

.calendar-filter-button--old {
  opacity: 0.5;
}
</style>
