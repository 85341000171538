<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 12"
    width="16"
    height="12"
  >
    <path
      d="M13.15 5.17H.73A.79.79 0 000 6a.79.79 0 00.73.83h12.42l-3.78 3.75a.83.83 0 101.17 1.18l5.21-5.17a.83.83 0 000-1.18L10.54.24a.83.83 0 10-1.17 1.18z"
      fill="#00136f"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'ArrowRight',
}
</script>

<style lang="scss">

</style>
