<template>
  <section class="calendar min-h-screen">
    <Header
      @next="next"
      @prev="prev"
      :title="$refs.calendar ? $refs.calendar.title : ''"
    />

    <div class="calendar-body bg-cream pt-16 pb-10">
      <div class="container">
        <v-calendar
          ref="calendar"
          v-model="value"
          :type="type"
          locale="pt-br"
          :weekdays="weekday"
          :first-interval="15"
          :interval-minutes="30"
          :interval-count="28"
          :short-intervals="false"
          :interval-height="64"
          :events="events"
          event-start="date"
          event-end="end_date"
          event-overlap-mode="column"
          @click:event="onEventClick"
        >
          <template #interval-header>
            <interval-header :date="value" />
          </template>

          <template v-slot:day-body="{ date, week }">
            <div
              class="v-current-time"
              :class="{ first: date === week[0].date }"
              :style="{ top: nowY }"
            ></div>
          </template>

          <template v-slot:event="{ event }">
            <date-card
              v-if="event.type === 'date'"
              :event="event"
              :selected="selectedDates.includes(event.date) || selectedUnavailableDates.includes(event.date)"
            />

            <event-card
              v-else
              :event="event"
            />
          </template>
        </v-calendar>
      </div>
    </div>

    <calendar-filter-modal
      :date="value"
      @change="changeDate"
    />

    <v-fade-transition>
      <action-button
        v-if="selectedDates.length || selectedUnavailableDates.length"
        @click="onActionClick"
        :type="selectedUnavailableDates.length ? 'delete' : 'save'"
      />
    </v-fade-transition>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import dayjs from 'dayjs';
import api from '@/utils/api.js';

import Header from '@/components/calendar/Header';
import DateCard from '@/components/DateCard';
import EventCard from '@/components/EventCard';
import ActionButton from '@/components/calendar/ActionButton';
import IntervalHeader from '@/components/calendar/IntervalHeader';
import CalendarFilterModal from '@/components/modals/CalendarFilterModal';

export default {
  name: 'Calendar',
  components: {
    ActionButton,
    DateCard,
    CalendarFilterModal,
    IntervalHeader,
    Header,
    EventCard,
  },
  data() {
    return {
      ready: false,
      value: '',
      type: 'week',
      available: [],
      unavailable: [],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      selectedDates: [],
      selectedUnavailableDates: [],
    };
  },
  computed: {
    ...mapState('consultant', ['user', 'reservations']),
    ...mapState(['hasUserModal']),

    events() {
      return [
        ...this.available,
        ...this.unavailable,
        ...this.reservations
      ]
    },

    calendar() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.calendar ? this.calendar.timeToY(this.calendar.times.now) + 'px' : '-10px';
    }
  },
  mounted() {
    this.init();
    this.getSchedule();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapMutations(['openUserModal']),
    ...mapActions('consultant', ['getReservations']),

    onEventClick({ event }) {
      if (event.type === 'date') {
        if (event.unavailable) {
          this.onDateUnavailableSelected(event);
        } else {
          this.onDateSelected(event);
        }
        return;
      }

      this.showEvent(event);
    },

    showEvent(event) {
     

      this.$store.commit('setUserModalMeta', event.user);
      this.$store.commit('setReservationModalMeta', event);
      this.openUserModal();
    },
    setToday() {
      this.value = '';
    },

    next() {
      this.$refs.calendar.next();
    },
    prev() {
      this.$refs.calendar.prev();
    },

    changeDate(newDate) {
      this.value = newDate;
    },

    getCurrentTime() {
      return this.calendar ? this.calendar.times.now.hour * 60 + this.calendar.times.now.minute : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.calendar.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.calendar.updateTimes(), 60 * 1000);
    },

    onDateSelected(event) {
      if (this.selectedUnavailableDates.length) {
        return;
      }

      const index = this.selectedDates.indexOf(event.date);

      if (index > -1) {
        this.selectedDates.splice(index, 1);
        return;
      }

      this.selectedDates.push(event.date);
    },

    onDateUnavailableSelected(event) {
      if (this.selectedDates.length) {
        return;
      }

      const index = this.selectedUnavailableDates.indexOf(event.date);

      if (index > -1) {
        this.selectedUnavailableDates.splice(index, 1);
        return;
      }

      this.selectedUnavailableDates.push(event.date);
    },

    onActionClick() {
      if (this.selectedDates.length) {
        this.makeDatesUnavailable();
      }

      if (this.selectedUnavailableDates) {
        this.makeDatesAvailable();
      }
    },

    async makeDatesUnavailable() {
      await api.post(`consultants/${this.user.id}/unavailable-time`, {
        dates: this.selectedDates
      });

      this.selectedDates = [];
      this.$noty.success('As datas selecionadas foram marcadas com indisponíveis');

      this.getSchedule();
    },

    async makeDatesAvailable() {
      await api.post(`consultants/${this.user.id}/unavailable-time`, {
        _method: 'delete',
        dates: this.selectedUnavailableDates
      });

      this.selectedUnavailableDates = [];
      this.$noty.success('As datas selecionadas foram marcadas com disponíveis');

      this.getSchedule();
    },

    async getSchedule() {
      const date = this.$refs.calendar.start;
      const start = dayjs(date).startOf('isoWeek');
      const end = dayjs(date).endOf('isoWeek');

      this.available = await this.getAvailable(start, end);
      this.unavailable = await this.getUnavailable(start, end);
    },

    async getUnavailable(start, end) {
      const from = start.format('YYYY-MM-DD');
      const to = end.format('YYYY-MM-DD');

      const url = `consultants/${this.user.id}/unavailable-time`;
      const response = await api.get(url, {
        params: { from, to }
      });

      return response.data
        .map((unavailable) => {
          const timestamp = dayjs(`${unavailable.date} ${unavailable.time}`);
          const date = timestamp.format('YYYY-MM-DD HH:mm');
          const end_date = timestamp.add(this.user.duration, 'minutes').format('YYYY-MM-DD HH:mm');

          return {
            date,
            end_date,
            timestamp,
            unavailable: true,
            type: 'date'
          };
        });
    },

    async getAvailable(start) {
      const today = dayjs().dayOfYear();
      const days = [];

      for (let i = 0; i < 7; i++) {
        days.push(start.add(i, 'day'));
      }

      const promises = days
        .filter((day) => {
          return day.dayOfYear() >= today;
        })
        .map((day) => {
          return this._getAvailable(day.format('YYYY-MM-DD'));
        });

      const available = await Promise.all(promises);
      return available.flat();
    },

    async _getAvailable(date) {
      const url = `consultants/${this.user.id}/availability`;
      const response = await api.get(url, {
        params: { date }
      });

      const payload = response.data;

      return payload.available
        .filter((time) => {
          return !payload.unavailable.includes(time);
        })
        .map((time) => {
          const timestamp = dayjs(`${payload.date} ${time}`);
          const date = timestamp.format('YYYY-MM-DD HH:mm');
          const end_date = timestamp.add(payload.duration, 'minutes').format('YYYY-MM-DD HH:mm');

          return {
            date,
            end_date,
            timestamp,
            type: 'date'
          };
        });
    },

    init() {
      this.ready = true;
      this.$refs.calendar.move(0);
      this.scrollToTime();
      this.updateTime();
      this.getReservations();

      window.scroll({
        top: this.calendar.timeToY(this.calendar.times.now),
        left: 0,
        behavior: 'smooth'
      })

      this.interval = setInterval(() => {
        this.getReservations();
      }, 10000);
    }
  },
};
</script>

<style lang="scss">
.calendar-header {
  box-shadow: 0 2px 20px rgba(0, 19, 111, 0.04);
}

.calendar-body {
  .v-calendar-daily__pane {
    height: auto !important;
  }

  .theme--light.v-calendar-daily {
    border-top: 0;
    border-left: 0;

    .v-calendar-daily__intervals-head,
    .v-calendar-daily__intervals-body,
    .v-calendar-daily__day {
      border-right: 1px solid rgba(81, 103, 207, 0.25);
    }

    .v-calendar-daily_head-day,
    .v-calendar-daily__day {
      border-bottom: 1px solid rgba(81, 103, 207, 0.25);
    }

    .v-calendar-daily__intervals-head,
    .v-calendar-daily__intervals-body {
      @apply flex-1;
    }

    .v-calendar-daily__intervals-head {
      @apply bg-cream border-0;

      &:after {
        background: rgba(81, 103, 207, 0.25);
      }
    }

    .v-calendar-daily__intervals-body {
      .v-calendar-daily__interval {
        border-left: 1px solid rgba(81, 103, 207, 0.25);
      }
    }

    .v-calendar-daily__interval {
      @apply p-0;

      &:last-child {
        &:after {
          bottom: -1px;
        }
      }

      &:after {
        width: 100%;
        border-top: 1px solid rgba(81, 103, 207, 0.25);
      }
    }

    .v-calendar-daily__day-interval {
      border-top: 0;
      border-bottom: 1px solid rgba(81, 103, 207, 0.25);
    }

    .v-calendar-daily__interval-text {
      @apply text-xs text-blue pr-0 text-center;

      top: auto;
      bottom: -40%;
    }

    .v-calendar-daily_head-day {
      @apply flex items-center justify-center pb-4 bg-cream text-lg pointer-events-none;
      border-right: 0;

      &:nth-child(7),
      &:nth-child(8) {
        .v-calendar-daily_head-weekday,
        .v-calendar-daily_head-day-label {
          opacity: 0.4;
        }
      }

      .v-calendar-daily_head-weekday {
        @apply text-blue;
      }

      &.v-present {
        .v-btn {
          background-color: theme("colors.blue") !important;
        }

        .v-btn__content {
          @apply text-white;
        }
      }
    }

    .v-btn {
      @apply ml-1 focus:outline-none;
    }

    .v-calendar-daily_head-day-label {
      @apply p-0;
    }
  }

  .v-calendar-daily__intervals-body {
    margin-top: -1px;

    .v-calendar-daily__interval {
      &:first-child {
        display: none;
      }
    }
  }

  .v-calendar-daily__day {
    margin-top: -64px;

    .v-calendar-daily__day-interval {
      &:nth-child(11) {
        display: none;
        border-top: 1px solid rgba(81, 103, 207, 0.4) !important;
      }
    }

    &:nth-child(7),
    &:nth-child(8) {
      .v-calendar-daily__day-interval {
        background: url("~@/assets/images/calendar-inactive-cell.png");
        background-color: rgba(0, 19, 111, 0.1);

        &:nth-child(11) {
          display: block;
          border: 0 !important;
        }
      }
    }

    &:nth-child(7) {
      border-right: 1px solid rgba(81, 103, 207, 0.4) !important;
    }
  }

  .v-calendar-daily_head-weekday,
  .v-btn.v-size--default {
    @apply text-lg text-blue capitalize;
  }

  .v-btn.v-size--default {
    @apply w-8 h-8;
  }

  .v-event-timed-container {
    @apply mr-0;
  }

  .v-calendar-events {
    .v-event-timed {
      z-index: 1;

      background-color: #fff;
      cursor: auto;

      width: 94%!important;
      border: none!important;
      white-space: normal;
      margin-left: 5px;
      transform: translateY(5px);
    }
  }

  .v-past {
    .v-event-timed {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    left: 0;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
</style>
