<template>
  <div class="flex items-center justify-center interval-header cursor-pointer text-center font-medium text-xl text-blue" @click="openCalendarFilter">
    {{ formattedDate }}

    <svg class="ml-3" width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.37515 4.10356C3.64698 4.3641 4.00943 4.3641 4.28126 4.10356C5.09675 3.32193 6.63713 1.84552 7.45262 1.06389C7.72445 0.803342 7.72445 0.455951 7.45262 0.195408C7.18079 -0.0651358 6.81835 -0.0651358 6.54652 0.195408C5.73102 0.977038 4.19065 2.45345 3.37515 3.23508C3.10332 3.49562 3.10332 3.84301 3.37515 4.10356Z" fill="#00136F"/>
      <path d="M4.28123 4.10356C4.55307 3.84301 4.55307 3.49562 4.28123 3.23508C3.46574 2.45345 1.92536 0.977038 1.10987 0.195408C0.838039 -0.0651358 0.475598 -0.0651358 0.203766 0.195408C-0.0680647 0.455951 -0.0680647 0.803342 0.203766 1.06389C1.01926 1.84552 2.55964 3.32193 3.37513 4.10356C3.64696 4.3641 4.0094 4.3641 4.28123 4.10356Z" fill="#00136F"/>
    </svg>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'IntervalHeader',
  props: ['date'],
  computed: {
    formattedDate() {
      return dayjs(this.date).format('MMM YYYY');
    }
  },
  methods: {
    ...mapMutations(['openCalendarFilter']),
  }
}
</script>

<style lang="scss">

</style>
