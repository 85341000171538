<template>
  <div
    class="calendar-event"
    :class="{ 'opacity-40': event.canceled_at, 'past-event' : now }"
  >
    <span class="mr-3 text-white text-opacity-50">{{ time }}</span>
    <span class="text-white text-sm">{{ event.user.first_name }}</span>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'EventCard',
  props: ['event'],
  computed: {
    date() {
      return dayjs(this.event.date);
    },
    time() {
      return this.date.format('HH:mm');
    },
    now() {
      return this.date.isBefore(dayjs());
    },
  },
}
</script>

<style lang="scss">
.calendar-event {
  @apply w-full h-full flex items-center justify-between whitespace-nowrap p-2 px-3 bg-blue-100 cursor-pointer;
  border-radius: 4px;
}

.past-event {
  opacity: 0.4;
  

  + .v-ripple__container {
    display: none;
  }
}
</style>
