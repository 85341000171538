<template>
  <header class="calendar-header w-full bg-white">
    <div class="container flex items-center justify-between h-20">
      <logo />
      <Button :to="{ name: 'ConsultantUsers' }" class="text-sm ml-6 focus:outline-none " small>Usuárias</Button>
      
      <div class="calendar-nav flex items-center flex-none">
        <button @click="prev" class="w-10 h-10 flex items-center justify-center focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12" width="16" height="12">
            <path d="M2.85 6.82h12.42A.79.79 0 0016 6a.79.79 0 00-.73-.83H2.85l3.79-3.75A.83.83 0 105.47.24L.25 5.4a.82.82 0 000 1.17l5.21 5.18a.83.83 0 101.17-1.17z" fill="#00136f" fill-rule="evenodd"/>
          </svg>
        </button>

        <h3 class="calendar-nav-label text-blue text-lg font-light">{{ title }}</h3>

        <button @click="next" class="w-10 h-10 flex items-center justify-center focus:outline-none mr-2">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 12" width="16" height="12">
            <path d="M13.15 5.17H.73A.79.79 0 000 6a.79.79 0 00.73.83h12.42l-3.78 3.75a.83.83 0 101.17 1.18l5.21-5.17a.83.83 0 000-1.18L10.54.24a.83.83 0 10-1.17 1.18z" fill="#00136f" fill-rule="evenodd"/>
          </svg>
        </button>
      </div>

      <div class="calendar-user flex items-center text-right flex-none">
        <div class="text-blue text-sm mr-4">
          <p>{{ user.name }}</p>
          <button @click="logout" class="font-light">Sair</button>
        </div>

        <avatar class="w-12 h-12" :image="user.avatar" :name="user.name" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Logo from '@/components/Logo';
import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
export default {
  name: 'Header',
  props: ['title'],
  components: { Avatar, Logo, Button },
  computed: {
    ...mapState('consultant', ['user'])
  },
  methods: {
    ...mapActions('consultant', ['logout']),

    next() {
      this.$emit('next');
    },
    prev() {
      this.$emit('prev');
    },
  }
}
</script>

<style lang="scss">

</style>
